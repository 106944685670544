/* Includes all page specific styling */

.main {
  @apply text-lg;

  &:global(.white) {
    @apply bg-white;
  }

  &:global(.summer-green) {
    @apply bg-summer-green-23;
  }

  & :global(> div[data-block="core/paragraph"]:last-of-type) {
    @apply pb-10 md:pb-15 lg:pb-20;
  }

  &:global(.has-search) {
    @apply pt-30;

    &:global(.no-hero) {
      @apply pt-44 sm:pt-50 lg:pt-60;
    }

    &:global(.has-admin-bar) {
      @apply mt-30;
    }
  }

  &:global(.no-hero) {
    @apply pt-14 sm:pt-20 lg:pt-30;

    &:global(.has-admin-bar) {
      @apply mt-10;
    }
  }

  &:global(.has-admin-bar) {
    @apply pt-12 sm:pt-12 md:pt-9;
  }
}
